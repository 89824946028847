import React from "react";
import { graphql } from "gatsby";

export const query = graphql`
  query ($id: ID!) {
    wp {
      page(id: $id) {
        id
        title
      }
    }
  }
`;

const PageTemplate = ({ data }) => {
  const page = data.wp.page;
  return (
    <div>
      <div className="">page-template.js</div>
      <h1>{page.title}</h1>
    </div>
  );
};

export default PageTemplate;
